import assert from 'react-share/lib/utils/assert';
import createShareButton from 'react-share/lib/hocs/createShareButton';
import objectToGetParams from 'react-share/lib/utils/objectToGetParams';

function hatenaLink(url: string, { title }: { title?: string }) {
  assert(url, 'hatena.url');

  return (
    'http://b.hatena.ne.jp/add' +
    objectToGetParams({
      mode:"confirm",
      url:url,
      title: title,
    })
  );
}

const HatenaShareButton = createShareButton<{ title?: string }>(
  'hatena',
  hatenaLink,
  props => ({
    title: props.title,
  }),
  {
    windowWidth: 500,
    windowHeight: 500,
  },
);

export default HatenaShareButton;